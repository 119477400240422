import React, { useContext, useRef } from 'react'
import { DynamicContext } from '../app/App'

import HeaderThumb from '../headerThumb/HeaderThumb'

import ReactHtmlParser from 'react-html-parser'

import './AppStudy.scss'

const AppStudy = React.forwardRef((props, ref) => {
	const dynamicImages = useContext(DynamicContext)

	const appCase = useRef(props.case).current

	function getImage(imageName) {
		// console.warn('GETTING IMAGE', imageName)
		return `${dynamicImages.dynamicFolder(`./${imageName}`)}`
	}

	return (
		<div ref={ref} className='page-intro'>
			<div className='intro-content'>
				<h2>
					{ReactHtmlParser(appCase.headline)}
					<span className='sub-copy'>
						{(() => {
							let string = ''
							appCase.tech.forEach((feature, i) => {
								string += `<span>/</span>${feature}`
							})
							return ReactHtmlParser(string)
						})()}
					</span>
				</h2>
				<p>{ReactHtmlParser(appCase.description)}</p>
				<br />
				{appCase.banner && (
					<img
						alt={appCase.banner}
						className={`banner${appCase.banner.target ? ' banner--link' : ''}`}
						src={getImage(appCase.banner.image)}
						onClick={
							appCase.banner.target
								? () => {
										window.open(appCase.banner.target, '_blank')
								  }
								: null
						}
					/>
				)}
				{appCase.thumbnails && (
					<div className='page-item top'>
						<div className='hightlights'>
							{appCase.thumbnails.map((thumb, i) => (
								<HeaderThumb key={`thumb_${thumb.image}`} index={i} data={thumb} />
							))}
						</div>
					</div>
				)}
			</div>
		</div>
	)
})
export default AppStudy
