import React, { useEffect, useState, useRef } from 'react'
import ReactHtmlParser from 'react-html-parser'

import gsap from 'gsap'
import useEffectOnce from '../../hooks/useEffectOnce'

import SvgButton from '../svgButton/SvgButton'

import './CaseInfo.scss'

const CaseInfo = React.forwardRef((props, ref) => {
	const baseURL = useRef('http://www.andrewdowis.com/html/').current
	const study = useRef(props.study).current
	const index = useRef(props.index).current
	const curNav = useRef()
	const iconLockupRef = useRef()
	const iframeRef = useRef()
	const contentRef = useRef()

	const folderRef = useRef()

	const [content, setContent] = useState(null)
	const [format, setFormat] = useState(null)

	const [isOpen, toggleIsOpen] = useState(false)

	useEffectOnce(() => {
		// useEffect(() => {
		let oldFun = window.onscroll
		window.onscroll = (e) => {
			let thisContent = contentRef.current
			if (thisContent) {
				// const size = getSize(thisContent)
				// console.warn(size)
				const bounds = thisContent.getBoundingClientRect()
				// if (bounds.width === 300 && bounds.height == 600) {
				// 	console.warn(bounds.bottom, window.innerHeight, bounds.top, 60, !format ? true : false)
				// }
				if (bounds.bottom < window.innerHeight && bounds.top > 60 && !format) {
					const attribute = thisContent.getAttribute('jsonid')
					const iconRefChildren = iconLockupRef.current.childNodes
					let domEl
					for (let i = 0, t = iconRefChildren.length; i < t; i++) {
						const child = iconRefChildren[i]
						if (child.getAttribute('jsonid') === attribute) {
							domEl = child
						}
					}
					showContent('html', attribute, domEl)
				}
				//  else if (format) {
				// 	console.warn('SET THE FORAMTs', attribute)
				// 	setFormat(null)
				// }
			}
			oldFun && oldFun()
		}
	})

	function showContent(newFormat, newURL, highlightItem) {
		let url2 = `${baseURL}${study.baseUrl.replace(/SIZE_VALUE/gi, newURL)}`

		function colorChildren(parent, turnOn) {
			const color = turnOn ? '#ffffff' : '#000000'
			for (let i = 0, t = parent.childNodes.length; i < t; i++) {
				gsap.set(parent.childNodes[i], { fill: color, color: color, overwrite: true })
			}
		}
		if (curNav.current) {
			curNav.current.classList.remove('nav-on')
			colorChildren(curNav.current, false)
		}
		if (highlightItem) {
			const highlight = highlightItem.current || highlightItem.childNodes[0]
			if (!highlightItem.current) colorChildren(highlight, true)

			highlight.classList.add('nav-on')
			curNav.current = highlight
		}

		setContent(url2)
		setFormat(newFormat)
	}

	function getSize(value) {
		var sizeName = value.match(/[0-9]+x[0-9]+/)
		return sizeName ? sizeName[0] : null
	}

	function getContent() {
		let [w, h] = ['auto', 'auto']
		if (!study.sizes || !study.sizes.length) return <></>
		if (format === 'html') {
			const size = getSize(content)
			if (size) [w, h] = size.split('x')

			return (
				<iframe
					key={`but${Math.random()}`}
					ref={iframeRef}
					title={'iframe'}
					width={w}
					height={h}
					src={content}
				/>
			)
		}
		//
		;[w, h] = study.sizes[0].split('x')
		return (
			<>
				<img
					key={`but${Math.random()}`}
					ref={contentRef}
					width={w}
					height={h}
					jsonid={study.sizes[0]}
					alt={'img'}
					src={`${baseURL}${study.thumb.replace(/SIZE_VALUE/gi, study.sizes[0])}`}
				/>
				<br />
			</>
		)
	}

	useEffect(() => {
		const domEl = folderRef.current
		if (domEl) {
			delete domEl.style.height
			const time = 0.5 //Math.max(0.5, Math.min(1, targetHeight * 0.0005))
			gsap.to(domEl, time, { height: isOpen ? 'auto' : 0 })
		}
	}, [isOpen])

	return (
		<div className='case-info'>
			<div className='case-expand'>
				<h3>{study.title}</h3>
				<div
					onClick={() => {
						toggleIsOpen(!isOpen)
					}}
					className='expand-but'>
					{`${isOpen ? 'Collapse -' : 'Expand +'}`}
				</div>
			</div>
			<div className='case-info-folder' ref={folderRef}>
				{study.description && <p className='description'>{ReactHtmlParser(study.description)}</p>}
				{study.tech && (
					<>
						<p className='sub-title'>Tools and Tech</p>
						<p className='tech'>{ReactHtmlParser(study.tech)}</p>
					</>
				)}
				{study.demoing && (
					<>
						<br />
						<p className='sub-title'>Showcasing</p>
						<p className='tech'>
							{(() => {
								let string = ''
								study.demoing.forEach((demo, i) => {
									string += demo
									if (i < study.demoing.length - 1) string += ' <span class="line-div">/</span> '
								})

								return ReactHtmlParser(string)
							})()}
						</p>
					</>
				)}
				{study.portfolio && (
					<>
						<br />
						<a target='_blank' rel='noopener noreferrer' href={study.portfolio}>
							<p>{`Company ${study.title} Portfolio Site`}</p>
						</a>
					</>
				)}
				{study.login && (
					<>
						<br />
						<p>{`Login UN/PW: ${study.login}`}</p>
					</>
				)}
				<br />
			</div>
			<div className='mini-line' />
			<div className='study-icon-lockup' ref={iconLockupRef}>
				{study.icons.map((icon, i) => {
					return <SvgButton {...icon} jsonid={icon.url} callback={showContent} key={`button_${index}${i}`} />
				})}
			</div>
			{getContent()}
			<br />
		</div>
	)
})
export default CaseInfo
