/* eslint-disable react/jsx-no-target-blank */
import React, { useRef } from 'react'
import ReactHtmlParser from 'react-html-parser'
// import gsap, { Elastic, Bounce, Back, Power0, Power1, Power2, Power3, Power4 } from 'gsap'
// import ScrollToPlugin from 'gsap/ScrollToPlugin'
import './App.scss'
// import useEffectOnce from '../../hooks/useEffectOnce'

import AppStudy from '../appStudy/AppStudy'
import CaseStudy from '../caseStudy/CaseStudy'
import LogosArray from '../logosArray/LogosArray'

import highlights from '../../assets/data/highlights.json'

export const DynamicContext = React.createContext()

function App() {
	// const highightsRef = useRef(null)
	const headerRef = useRef(null)
	const topRef = useRef(null)
	const caseRef = useRef(null)
	const bottomRef = useRef(null)

	// const showcase = ['React', 'Hooks', 'MongoDB', 'Express', 'REST']
	const dynamicFolder = useRef(require.context('../../assets/images', true)).current

	return (
		<DynamicContext.Provider value={{ dynamicFolder }}>
			<div className='App'>
				<div ref={headerRef} className='site-header'>
					<h1>
						<span>A</span>NDREW <span>D</span>OWIS
					</h1>
					<h2>
						front-end developer and interactive animator {'//'}{' '}
						<a href='https://www.linkedin.com/in/andrewdowis/' target='_blank' rel='noopener noreferrer'>
							LinkedIn
						</a>{' '}
						{'//'}{' '}
						<a href='https://github.com/andrewdowis' target='_blank' rel='noopener noreferrer'>
							GitHub
						</a>{' '}
						{'//'}{' '}
						<a href='mailto:adowis@gmail.com' target='_blank' rel='noopener noreferrer'>
							adowis@gmail.com
						</a>{' '}
						{'//'} resume{' '}
						<a href='./documents/AndrewDowis_Resume_2020.pdf' target='_blank' rel='noopener noreferrer'>
							PDF
						</a>
					</h2>
				</div>
				<LogosArray />

				{highlights.apps &&
					highlights.apps.map((data, i) => {
						return <AppStudy key={`app_${i}`} ref={caseRef} upperRef={topRef} case={data} caseIndex={i} />
					})}

				{highlights.nodes &&
					highlights.nodes.map((data, i) => {
						return <CaseStudy key={`case_${i}`} ref={caseRef} upperRef={topRef} case={data} caseIndex={i} />
					})}

				<div ref={bottomRef} className='page-item bottom'>
					<br />
					<br />
					<h1>Companies</h1>
					{(() => {
						let string = ''
						let array = [
							'160over90',
							'RED',
							'Endeavor Global Marketing',
							'WME IMG',
							'AvatarLabs',
							'Sony Pictures',
							'Imageworks',
							'...and maybe yours?',
						]
						array.forEach((cms, i) => {
							string += cms
							if (i < array.length - 1) string += ' <span class="line-div">|</span> '
						})

						return <p>{ReactHtmlParser(string)}</p>
					})()}
					<br />
					<h1>Platforms</h1>
					{(() => {
						let string = ''
						let array = [
							'AdDroid',
							'FlashTalking',
							'Google DCM + Studio / DV360',
							'Netflix Monet',
							'Velvet',
						]
						array.forEach((cms, i) => {
							string += cms
							if (i < array.length - 1) string += ' <span class="line-div">|</span> '
						})

						return <p>{ReactHtmlParser(string)}</p>
					})()}
					<br />
					<h1>Properties</h1>
					{(() => {
						let string = ''
						let array = [
							'Ghostbusters',
							'Men In Black',
							'The Smurfs',
							'Spider-Man',
							'Terminator',
							'and more!',
						]
						array.forEach((cms, i) => {
							string += cms
							if (i < array.length - 1) string += ' <span class="line-div">|</span> '
						})

						return <p>{ReactHtmlParser(string)}</p>
					})()}
					<br />
					<p></p>
					<br />
				</div>
			</div>
		</DynamicContext.Provider>
	)
}

export default App
