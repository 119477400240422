import React, { useContext } from 'react'

import { DynamicContext } from '../app/App'

const LogosArray = React.forwardRef((props, ref) => {
	const dynamicImages = useContext(DynamicContext)

	function getLogo(logoName) {
		return `${dynamicImages.dynamicFolder(`./logos/logo_${logoName}.png`)}`
	}

	return (
		<div ref={ref} className='page-item logo-top'>
			<h1>proudly serving and collaborating with clients such as</h1>
			<br />
			{/* <div className='line-div line-wide' /> */}
			<div className='logos'>
				<img alt='logo-client' src={getLogo('abc2')} />
				<img alt='logo-client' src={getLogo('amc')} />
				<img alt='logo-client' src={getLogo('apple')} />
				<img alt='logo-client' className='wide' src={getLogo('audi')} />
				<img alt='logo-client' src={getLogo('budlight')} />
				<img alt='logo-client' src={getLogo('disney')} />
				<img alt='logo-client' className='wide' src={getLogo('espn')} />
				<img alt='logo-client' className='wide' src={getLogo('facebook')} />
				<img alt='logo-client' src={getLogo('20thfox')} />
				<img alt='logo-client' className='wide' src={getLogo('hulu')} />
				<img alt='logo-company' className='wider' src={getLogo('imageworks')} />
				<img alt='logo-client' className='wider' src={getLogo('livenation')} />
				<img alt='logo-client' src={getLogo('mattel')} />
				<img alt='logo-client' className='wide' src={getLogo('msnbc')} />
				<img alt='logo-client' className='wide' src={getLogo('netflix')} />
				<img alt='logo-client' className='wide' src={getLogo('nike')} />
				<img alt='logo-company' src={getLogo('sonyPictures')} />
				<img alt='logo-client' src={getLogo('snapchat')} />
				<img alt='logo-client' src={getLogo('universal')} />
				<img alt='logo-client' src={getLogo('underArmour')} />
			</div>
		</div>
	)
})
export default LogosArray
