import React, { useEffect, useState, useRef } from 'react'
import gsap from 'gsap'
// import useEffectOnce from '../../hooks/useEffectOnce'

import { ReactComponent as GitHub } from '../../assets/images/icon_github.svg'
import { ReactComponent as Site } from '../../assets/images/icon_site.svg'
import { ReactComponent as Video } from '../../assets/images/icon_video.svg'
import { ReactComponent as Game } from '../../assets/images/icon_game.svg'
import { ReactComponent as HTML } from '../../assets/images/icon_play2.svg'

import './SvgButton.scss'

const SvgButton = React.forwardRef((props, ref) => {
	// const [expand, setExpand] = useState(props.expand)
	const domRef = useRef(null)
	const svgRef = useRef(null)
	const copyRef = useRef(null)

	const CustomTag = props.target === 'video' || props.target === 'html' ? 'div' : 'a'
	const buttonProps =
		CustomTag === 'div'
			? {
					onClick: () => {
						props.callback(props.tag, props.url, domRef)
					},
			  }
			: {
					href: props.url,
					target: props.target || '',
			  }

	const [tagState, setTagState] = useState()

	useEffect(() => {
		setTagState(() => {
			const svgProps = {
				ref: svgRef,
				className: 'svg-icon',
				style: {
					fill: '#000000',
				},
			}
			switch (props.tag) {
				case 'github':
					return <GitHub {...svgProps} />
				case 'video':
					return <Video {...svgProps} />
				case 'game':
					return <Game {...svgProps} />
				case 'html':
					return <HTML {...svgProps} />
				case 'site':
				default:
					return <Site {...svgProps} />
			}
		})
	}, [props.tag])

	function handleOver(evt) {
		if (svgRef.current) gsap.to(svgRef.current, 0.25, { fill: '#00aaff', overwrite: true })
		if (copyRef.current) gsap.to(copyRef.current, 0.25, { color: '#00aaff', overwrite: true })
	}

	function handleOut(evt) {
		const newColor = domRef.current.classList.value.indexOf('nav-on') >= 0 ? '#ffffff' : '#000000'
		if (svgRef.current) gsap.to(svgRef.current, 0.25, { fill: newColor, overwrite: true })
		if (copyRef.current) gsap.to(copyRef.current, 0.25, { color: newColor, overwrite: true })
	}

	return (
		<CustomTag {...buttonProps} jsonid={props.url}>
			<div ref={domRef} className='icon-lockup' onMouseEnter={handleOver} onMouseLeave={handleOut}>
				{tagState}
				{props.copy && (
					<p
						ref={copyRef}
						style={{
							marginLeft: tagState ? null : '0',
						}}>
						{props.copy}
					</p>
				)}
				{/* {props.copy && <p>{'props.copy'}</p>} */}
			</div>
		</CustomTag>
	)
})

export default SvgButton
