import React, { useRef } from 'react'
import ReactHtmlParser from 'react-html-parser'

// import gsap, { Elastic, Bounce, Back, Power0, Power1, Power2, Power3, Power4 } from 'gsap'
// import ScrollToPlugin from 'gsap/ScrollToPlugin'

// import SvgButton from '../svgButton/SvgButton'
// import OverlayBox from '../overlayBox/OverlayBox'
import CaseInfo from '../caseInfo/CaseInfo'

// import CloseButton from '../closeButton/CloseButton'

import './CaseStudy.scss'

const CaseStudy = React.forwardRef((props, ref) => {
	const caseInfo = useRef(props.case).current

	return (
		<div ref={ref} className='case-container'>
			{/* <div className='case-shadow case-shadow-top' /> */}
			{/* <CloseButton
				ref={closeRef}
				onClick={() => {
					overlay ? setOverlay(null) : props.close()
				}}
			/> */}
			{/* {overlay ? ( */}
			{/* <OverlayBox data={overlay} close={setOverlay} /> */}
			{/* ) : ( */}
			<div className='case-study'>
				<h2>
					{ReactHtmlParser(caseInfo.headline)}
					<span className='sub-copy'>
						{(() => {
							let string = ''
							caseInfo.tech.forEach((feature, i) => {
								string += `<span>/</span>${feature}`
							})
							return ReactHtmlParser(string)
						})()}
					</span>
				</h2>
				<p>{ReactHtmlParser(caseInfo.description)}</p>
				<br />
				{caseInfo.tools && (
					<>
						<p className='sub-title'>Tools</p>
						{(() => {
							let string = ''
							caseInfo.tools.forEach((tool, i) => {
								string += tool
								if (i < caseInfo.tools.length - 1) string += ' <span class="line-div">/</span> '
							})

							return <p>{ReactHtmlParser(string)}</p>
						})()}
					</>
				)}
				<br />
				{caseInfo.cms && (
					<>
						<p className='sub-title'>CMS Platforms</p>
						{(() => {
							let string = ''
							caseInfo.cms.forEach((cms, i) => {
								string += cms
								if (i < caseInfo.cms.length - 1) string += ' <span class="line-div">/</span> '
							})

							return <p>{ReactHtmlParser(string)}</p>
						})()}
					</>
				)}
				<br />
				{caseInfo.content.map((study, s) => {
					return <CaseInfo study={study} index={s} key={`case${s}`} />
				})}
			</div>
			{/* )} */}
			{/* <div className='case-shadow case-shadow-bottom' /> */}
		</div>
	)
})
export default CaseStudy
