import React, { useContext, useRef } from 'react'
import { DynamicContext } from '../app/App'

import './HeaderThumb.scss'

function HeaderThumb(props) {
	// const [expand, setExpand] = useState(props.expand)
	// const bgColor = useRef(['red', 'black', 'blue', 'green'])
	const domRef = useRef(null)
	const images = useRef(require.context('../../assets/images', true)).current

	const data = useRef(props.data).current
	const clickTarget = useRef(data.target).current

	// console.log(`%c ${props.image}`, 'color: black; background-color: cyan; font-style: italic; padding: 10px;')

	const dynamicImages = useContext(DynamicContext)

	function getImage(imageName) {
		// console.warn('GETTING IMAGE', imageName)
		return `${dynamicImages.dynamicFolder(`./${imageName}`)}`
	}

	return (
		images && (
			<div
				ref={domRef}
				className={`header-thumb sub-thumb${clickTarget ? ' header-thumb--link' : ''}`}
				style={{
					backgroundImage: `url(${`./${getImage(data.image)}`})`,
				}}
				onClick={
					clickTarget
						? () => {
								window.open(clickTarget, '_blank')
						  }
						: null
				}>
				{data.copy && (
					<div className='info' style={{ backgroundColor: data.color || 'transparent' }}>
						{data.copy}
					</div>
				)}
			</div>
		)
	)
}

export default HeaderThumb
